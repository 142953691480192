<template>
  <div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="$router.go(-1)"/>

        <ProgressIndicator :currentPage="3" :totalPages="3"/>
      </div>
    </nav>

    <form
        @submit.prevent="submit"
        class="w-full px-4 pb-48 mt-10 md:px-12"
        enctype="multipart/form-data"
    >
      <div class="form-page page-3">
        <h2 class="form-page-title">
          {{ $t("business_request.page_3.title") }}
        </h2>

        <div class="w-full mb-6 sm:mb-8">
          <div class="">
            <p class="label">
              {{ $t("business_request.page_3.dmc.label") }}
            </p>

            <RadioButtonGroup
                @blur="$v.form.dmc.$touch()"
                v-model="form.dmc"
                :options="options.dmc"
                :disabled="loading"
            />

            <span
                class="error-msg"
                v-if="$v.form.dmc.$error && !$v.form.dmc.required"
            >
              {{ $t("business_request.page_3.dmc.error") }}
            </span>
          </div>
          <div class="my-6 w-full">
            <MultiSelect
                :label="$t('business_request.page_3.destinations.label')"
                :placeholder="
                $t('business_request.page_3.destinations.placeholder')
              "
                class="block w-full"
                :options="africanDestinations"
                :optionDisplayValue="
                (option) => {
                  return option.name;
                }
              "
                v-model="form.destinations"
                :errorMsg="destinationsErrMsg"
                :showErrMsg="$v.form.destinations.$error"
            />
          </div>

          <div class="mb-6 sm:mb-8">
            <p class="label">
              {{ $t("business_request.page_3.license.label") }}
            </p>

            <RadioButtonGroup
                @blur="$v.form.license.$touch()"
                v-model="form.license"
                :options="options.license"
                :disabled="loading"
            />

            <span
                class="error-msg"
                v-if="$v.form.license.$error && !$v.form.license.required"
            >
              {{ $t("business_request.page_3.license.error") }}
            </span>
          </div>

          <div class="mb-6 sm:mb-8">
            <p class="label">
              {{ $t("business_request.page_3.insurance.label") }}
            </p>

            <RadioButtonGroup
                @blur="$v.form.insurance.$touch()"
                v-model="form.insurance"
                :options="options.insurance"
                :disabled="loading"
            />

            <span
                class="error-msg"
                v-if="$v.form.insurance.$error && !$v.form.insurance.required"
            >
              {{ $t("business_request.page_3.insurance.error") }}
            </span>
          </div>

          <InputText
              name="website"
              :label="$t('business_request.page_3.website.label')"
              :placeholder="$t('business_request.page_3.website.placeholder')"
              class="mt-8 sm:mb-8"
              :disabled="loading"
              v-model="form.website"
          />

          <InputText
              name="operation-start"
              :label="$t('business_request.page_3.operations.label')"
              :placeholder="$t('business_request.page_3.operations.placeholder')"
              v-model="form.startDate"
              :disabled="loading"
              :errorMsg="startDateErrMsg"
              :showErrMsg="$v.form.startDate.$error"
              @blur="$v.form.startDate.$touch()"
          />

          <div class="mb-6 sm:mb-8">
            <p class="mb-2 font-sans font-semibold text-black-base">
              {{ $t("business_request.page_3.languages.label") }}
            </p>

            <div
                class="flex flex-wrap my-6"
                v-for="language in languages"
                :key="language.value"
            >
              <label class="flex items-center w-full">
                <input
                    type="checkbox"
                    class="form-checkbox"
                    v-model="form.languages"
                    :value="language.value"
                />
                <span
                    class="ml-3 text-sm text-black-base border-black-lightest"
                >
                  {{ language.name }}
                </span>
              </label>
            </div>

            <span
                class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
                v-if="$v.form.languages.$error && !$v.form.languages.required"
            >
              {{ $t("business_request.page_3.languages.error") }}
            </span>
          </div>

          <div>
            <InputUpload
                name="photo"
                :showImgGroup="true"
                :label="$t('business_request.page_3.upload.label')"
                :title="$t('business_request.page_3.upload.title')"
                :text="$t('business_request.page_3.upload.text')"
                :showDescription="true"
                :description="$t('business_request.page_3.upload.description')"
                allowedFileTypes=".jpg, .png, .jpeg"
                v-model="form.photo"
                :isError="$v.form.photo.$error"
                @input="$v.form.photo.$touch()"
                @upload="upload"
            />

            <span
                class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
                v-if="$v.form.photo.$error && !$v.form.photo.requiredIf"
            >
              {{ $t("business_request.page_3.upload.error") }}
            </span>
          </div>
        </div>

        <ButtonPrimary
            :loading="loading"
            :disabled="loading"
            type="submit"
            :text="$t('business_request.button_send')"
            class="desktop-cta"
        />
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">
          <ButtonPrimary
              :loading="loading"
              :disabled="loading"
              type="submit"
              class="block w-full h-12 px-8"
              :text="$t('business_request.button_send')"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import RadioButtonGroup from "@/components/inputs/RadioButtonGroup";
import InputUpload from "@/components/inputs/InputUpload";
import ButtonIcon from "@/components/buttons/ButtonIcon";
import MultiSelect from "@/components/inputs/MultiSelect/MultiSelect";

const {required, requiredIf} = require("vuelidate/lib/validators");

import {mapActions, mapGetters} from "vuex";

export default {
  name: "operation-details",
  components: {
    ButtonIcon,
    RadioButtonGroup,
    InputUpload,
    MultiSelect,
  },
  data() {
    return {
      loading: false,
      form: {
        dmc: "",
        destinations: [],
        license: "",
        insurance: "",
        website: "",
        startDate: "",
        languages: [],
        photo: "",
      },
      show_thank_you: "",
    };
  },
  validations: {
    form: {
      dmc: {
        required,
      },
      destinations: {
        required,
      },
      license: {
        required,
      },
      insurance: {
        required,
      },
      startDate: {
        required,
      },
      languages: {
        required,
      },
      photo: {
        required: requiredIf(function () {
          return !this.form.photo;
        }),
      },
    },
  },
  computed: {
    options() {
      return {
        dmc: [
          {
            label: this.$t("business_request.page_3.dmc.yes"),
            name: "dmc",
            value: 1,
          },
          {
            label: this.$t("business_request.page_3.dmc.no"),
            name: "dmc",
            value: 0,
          },
        ],
        license: [
          {
            label: this.$t("business_request.page_3.license.yes"),
            name: "license",
            value: 1,
          },
        ],
        insurance: [
          {
            label: this.$t("business_request.page_3.insurance.yes"),
            name: "insurance",
            value: 1,
          },
          {
            label: this.$t("business_request.page_3.insurance.no"),
            name: "insurance",
            value: 0,
          },
        ],
      };
    },
    languages() {
      return [
        {
          value: "Chinese",
          name: this.$t("business_request.page_3.languages.values.cn"), //Chinese
        },
        {
          value: "English",
          name: this.$t("business_request.page_3.languages.values.en"), //English
        },
        {
          value: "French",
          name: this.$t("business_request.page_3.languages.values.fr"), //French
        },
        {
          value: "German",
          name: this.$t("business_request.page_3.languages.values.de"), //German
        },
        {
          value: "Italian",
          name: this.$t("business_request.page_3.languages.values.it"), //Italian
        },
        {
          value: "Portuguese",
          name: this.$t("business_request.page_3.languages.values.pt"), //Portuguese
        },
        {
          value: "Other",
          name: this.$t("business_request.page_3.languages.values.other"), // Other
        },
      ];
    },
    africanDestinations() {
      return localStorage.getItem("lang") == "fr"
          ? this.$africanDestinationsFrench
          : this.$africanDestinationsEnglish;
    },
    ...mapGetters({
      personalDetails: "partner/personalDetails",
      companyDetails: "partner/companyDetails",
      operationDetails: "partner/operationDetails",
    }),
    destinationsErrMsg() {
      let msg;
      if (!this.$v.form.destinations.required) {
        msg = this.$t("business_request.page_3.destinations.error"); //Destination is required
      }
      return msg;
    },
    startDateErrMsg() {
      let msg;
      if (!this.$v.form.startDate.required) {
        msg = this.$t("business_request.page_3.operations.error"); //Date of operations start is required
      }
      return msg;
    },
  },
  methods: {
    ...mapActions({createPartner: "partner/createPartner"}),
    upload(val) {
      this.form.photo = val;
    },
    async submit() {
      try {
        this.loading = true;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return false;
        }
        this.$store.commit("partner/SET_OPERATION_DETAILS", this.form);
        const data = {
          ...this.personalDetails,
          ...this.companyDetails,
          ...this.operationDetails,
        };
        const selectedDestinations = this.africanDestinations.reduce(
            (container, currentItem) => {
              if (this.form.destinations.includes(currentItem.id))
                container.push(currentItem.value);
              return container;
            },
            []
        );

        data.destinations = selectedDestinations.toString();
        const res = await this.createPartner(data);
        if (!res) return false;
        this.loading = false;
        this.$notify({
          type: "success",
          title: "Your request was sent successfully", //res.data.message
        });
        localStorage.setItem("show_thanks", true);
        this.$router.push("/business/thank-you");
        localStorage.removeItem("country_code");
      } catch (error) {
        this.$notify({
          type: "error",
          title: error.message, //res.data.message
        });
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (Object.keys(this.operationDetails).length) {
      this.form = this.operationDetails;
    }
  },
};
</script>

<style scoped>
.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold  sm:text-2xl text-black-base;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white;
  @apply border-t border-grey-base sm:mt-16;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between;
  @apply max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}

.label {
  @apply mb-2 font-sans font-semibold text-black-base;
}

.form-checkbox {
  @apply w-5 h-5 border rounded border-black-lightest;
}

.error-msg {
  @apply block mt-2 font-sans text-sm font-medium text-red-base;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}
</style>
